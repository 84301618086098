import { useEffect } from 'react';

import { maskMetaAccount } from 'common/utils/stringUtils';
import { NETWORK_CHAIN_ID } from 'common/constants';
import PATHS from 'routing/paths';

import { Button } from 'components/button/Button';
import * as S from 'components/header/styles';
import { changeChain } from 'apis/ethereumApi';

import iceLotteryLogo from 'assets/svgs/ice-lottery-logo.svg';
import metaMaskIcon from 'assets/svgs/metamask-icon.svg';
import {
  isMetaMaskInstalled,
  redirectToMetaMaskDownloadPage,
} from 'common/utils/metaMaskUtils';
import { useNavigate } from 'react-router-dom';

const Header = ({
  accounts,
  requestAccounts,
  allowanceCheck,
  balanceCheck,
  requestChainId,
  chainId,
  getLotteryInfo,
  currentLotteryBlock,
  lotteryId,
}) => {
  let navigate = useNavigate();

  useEffect(() => {
    handleConnectMetaMask();

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleRefreshApp);
      window.ethereum.on('chainChanged', handleRefreshApp);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentLotteryBlock && lotteryId) {
      getLotteryInfo({
        blockId: currentLotteryBlock,
        lotteryId: lotteryId - 1,
      });
    }
  }, [currentLotteryBlock, getLotteryInfo, lotteryId]);

  useEffect(() => {
    if (accounts?.length > 0) {
      allowanceCheck(accounts[0]);
      balanceCheck(accounts[0]);
      requestChainId();
    }
  }, [accounts, allowanceCheck, balanceCheck, requestChainId]);

  const handleRefreshApp = () => {
    window.location.reload();
  };

  const handleConnectMetaMask = () => {
    requestAccounts();
    requestChainId();
    balanceCheck();
  };

  const ethereumChainChange = () => {
    changeChain();
  };

  const handleOpenHomePage = () => {
    if (window.location.pathname !== '/') {
      window.open('/', '_self');
    }
  };

  const handleOpenPastLotteries = () => {
    navigate(PATHS.PAST_LOTTERIES);
  };

  const handleOpenDocs = () => {
    window.open('https://docs.icelottery.net/', '_blank');
  };

  return (
    <S.HeaderContainer>
      <S.TitleWrapper>
        <S.Logo src={iceLotteryLogo} />
        <S.Title>ICE LOTTERY</S.Title>
        <S.NavTitle onClick={handleOpenHomePage} href={'#buy-ticket'}>
          Buy Ticket
        </S.NavTitle>
        <S.NavTitle onClick={handleOpenPastLotteries}>
          Past Lotteries
        </S.NavTitle>
        <S.NavTitle onClick={handleOpenDocs}>DOCS</S.NavTitle>
      </S.TitleWrapper>

      {!isMetaMaskInstalled() ? (
        <Button onClick={redirectToMetaMaskDownloadPage}>
          Download MetaMask!
        </Button>
      ) : (
        <>
          {!accounts?.length && (
            <Button onClick={handleConnectMetaMask}>Connect MetaMask!</Button>
          )}
          {accounts?.length > 0 && (
            <>
              {chainId === NETWORK_CHAIN_ID ? (
                <S.ProfileNameArea>
                  {maskMetaAccount(accounts[0])}
                  <S.ProfilePicture src={metaMaskIcon} alt="profile-picture" />
                </S.ProfileNameArea>
              ) : (
                <Button onClick={ethereumChainChange}>
                  Switch to Polygon Network
                </Button>
              )}
            </>
          )}
        </>
      )}
    </S.HeaderContainer>
  );
};

export default Header;
