import { connect } from 'react-redux';

import PastLotteries from 'modules/past-lotteries/PastLotteries';

import {
  getTicketsForPastLotteries,
  getLotteryBlockId,
  getLotteryInfo,
  checkLotteryId,
} from 'state/slices/accountSlice';

const mapStateToProps = (state) => ({
  lotteryId: state.account.lotteryId,
  lotteryInfo: state.account.lotteryInfo,
});

const mapDispatchToProps = {
  checkLotteryId,
  getTicketsForPastLotteries,
  getLotteryBlockId,
  getLotteryInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastLotteries);
