export const bigTitle = `
    font-size: 60px;
    font-weight: 800;
`;

export const title = `
    font-size: 32px;
    font-weight: 800;
`;

export const titleSmallScreen = `
    font-size: 16px;
    font-weight: 800;
`;

export const subTitle = `
    font-size: 21px;
    font-weight: 600;
`;

export const bigCaption = `
    font-size: 18px;
    font-weight: 600;
`;

export const caption = `
    font-size: 16px;
    font-weight: 600;
`;

export const body = `
    font-size: 14px;
    font-weight: 600;
`;
