import Routes from 'routing/Routes';
import { BrowserRouter as Router } from 'react-router-dom';

import Footer from 'components/footer/Footer';
import HeaderContainer from 'components/header/HeaderContainer';

const App = () => {
  return (
    <>
      <Router>
        <HeaderContainer />
        <Routes />
      </Router>
      <Footer />
    </>
  );
};

export default App;
