import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as ethereumApi from 'apis/ethereumApi';
import * as web3Api from 'apis/web3Api';
import { BUY_TICKET_BUTTON_STATES, MAX_UINT_256 } from 'common/constants';

interface AccountModel {
  accounts?: [];
  allowance?: String;
  balance?: String;
  chainId?: String;
  tickets?: any;
  buyTicketButtonState?: String;
  buyTicketResult?: any;
  lotteryState?: String;
  isTestMode?: boolean;
  isTestPlayer?: boolean;
  latestBlockNumber?: any;
  lotteryId?: any; // TODO: rename with activeLotteryId
  lotteryInfo: {};
  currentLotteryBlock?: number;
}

const INITIAL_STATE = {
  buyTicketButtonState: BUY_TICKET_BUTTON_STATES.READY,
  lotteryInfo: {},
} as AccountModel;

export const requestAccounts = createAsyncThunk('account/requestAccounts', () =>
  ethereumApi.requestAccounts()
);

export const requestChainId = createAsyncThunk('account/chainId', () =>
  ethereumApi.requestChainId()
);

export const allowanceCheck = createAsyncThunk(
  'account/allowanceCheck',
  (account: String) => web3Api.allowanceCheck(account)
);

export const balanceCheck = createAsyncThunk(
  'account/balanceCheck',
  (account: String) => web3Api.balanceCheck(account)
);

export const approve = createAsyncThunk('account/approve', (account: String) =>
  web3Api.approve(account)
);

export const getTickets = createAsyncThunk(
  'account/getTickets',
  (data: { fromBlockId: number; toBlockId: number; lotteryId: number }) =>
    web3Api.getTickets(data)
);

export const getTicketsForPastLotteries = createAsyncThunk(
  'account/getTicketsForPastLotteries',
  (data: { fromBlockId: number; toBlockId: number; lotteryId: number }) =>
    web3Api.getTickets(data)
);

export const buyTicket = createAsyncThunk(
  'account/buyTickets',
  (data: { ticketCount: number; account: String }) => web3Api.buyTicket(data)
);

export const getLotteryState = createAsyncThunk('account/getLotteryState', () =>
  web3Api.getLotteryState()
);

export const getLatestBlockNumber = createAsyncThunk(
  'account/getLatestBlockNumber',
  () => web3Api.getLatestBlockNumber()
);

export const checkLotteryId = createAsyncThunk('account/checkLotteryId', () =>
  web3Api.checkLotteryId()
);

export const getTestModeStatus = createAsyncThunk(
  'account/getTestModeStatus',
  () => web3Api.getTestModeStatus()
);

export const checkIsTestPlayer = createAsyncThunk(
  'account/checkIsTestPlayer',
  (account) => web3Api.checkIsTestPlayer(account)
);

export const getLotteryInfo = createAsyncThunk(
  'account/getLotteryInfo',
  (data: { blockId: number; lotteryId: string }) =>
    web3Api.getLotteryInfo(data.blockId, data.lotteryId)
);

export const getLotteryBlockId = createAsyncThunk(
  'account/getLotteryBlockId',
  (lotteryId: number) => web3Api.getLotteryBlockId(lotteryId)
);

const accountSlice = createSlice({
  name: 'account',
  initialState: INITIAL_STATE,
  reducers: {
    resetBuyTicketResult: (state) => {
      state.buyTicketResult = null;
    },
    resetTickets: (state) => {
      state.tickets = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload;
    });
    builder.addCase(allowanceCheck.fulfilled, (state, action) => {
      state.allowance = action.payload;
    });
    builder.addCase(approve.pending, (state) => {
      state.buyTicketButtonState = BUY_TICKET_BUTTON_STATES.PENDING;
    });
    builder.addCase(approve.rejected, (state) => {
      state.buyTicketButtonState = BUY_TICKET_BUTTON_STATES.READY;
    });
    builder.addCase(approve.fulfilled, (state) => {
      state.allowance = MAX_UINT_256;
      state.buyTicketButtonState = BUY_TICKET_BUTTON_STATES.READY;
    });
    builder.addCase(balanceCheck.fulfilled, (state, action) => {
      state.balance = action.payload;
    });
    builder.addCase(requestChainId.fulfilled, (state, action) => {
      state.chainId = action.payload;
    });
    builder.addCase(getTickets.fulfilled, (state, action) => {
      if (state.tickets) state.tickets = [...action.payload, ...state.tickets];
      else state.tickets = action.payload;
      state.tickets.sort(
        (a, b) => b.returnValues.timestamp - a.returnValues.timestamp
      );
    });
    builder.addCase(buyTicket.pending, (state) => {
      state.buyTicketButtonState = BUY_TICKET_BUTTON_STATES.PENDING;
    });
    builder.addCase(buyTicket.fulfilled, (state, action) => {
      state.buyTicketButtonState = BUY_TICKET_BUTTON_STATES.READY;
      state.buyTicketResult = action.payload;
    });
    builder.addCase(buyTicket.rejected, (state, action) => {
      state.buyTicketButtonState = BUY_TICKET_BUTTON_STATES.READY;
      state.buyTicketResult = action.error;
    });
    builder.addCase(getLotteryState.fulfilled, (state, action) => {
      if (state.lotteryState && state.lotteryState !== action.payload) {
        window.location.reload();
      } else {
        state.lotteryState = action.payload;
      }
    });
    builder.addCase(getTestModeStatus.fulfilled, (state, action) => {
      state.isTestMode = action.payload;
    });
    builder.addCase(checkIsTestPlayer.fulfilled, (state, action) => {
      state.isTestPlayer = action.payload;
    });
    builder.addCase(checkLotteryId.fulfilled, (state, action) => {
      state.lotteryId = action.payload;
    });
    builder.addCase(getLotteryInfo.fulfilled, (state, action) => {
      state.lotteryInfo[action.payload?.[0]?.returnValues?.lotteryId] =
        action.payload?.[0];
    });
    builder.addCase(getLotteryBlockId.fulfilled, (state, action) => {
      state.currentLotteryBlock = action.payload;
    });
  },
});

const { actions, reducer } = accountSlice;

export const { resetBuyTicketResult, resetTickets } = actions;

export { actions, reducer };
