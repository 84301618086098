import styled from 'styled-components';

import { Button, OutlineButton } from 'components/button/Button';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';
import * as zIndexes from 'common/zIndexes';

export const TicketAreaWrapper = styled.div<{ $isMasked?: boolean }>`
  display: flex;
  margin: 48px auto;
  max-width: 960px;
  width: 100%;
  height: 680px;

  align-items: center;
  justify-content: center;

  ${fonts.caption}

  ${(props) =>
    props.$isMasked
      ? `
          filter: blur(4px);
        `
      : null}

  @media (max-width: 960px) {
    flex-direction: column;
    height: auto;
  }
`;

export const TicketAreaColumn = styled.div`
  display: flex;
  height: 100%;
  flex: 2;

  flex-direction: column;

  &:nth-child(2) {
    flex: 3;
  }

  @media (max-width: 960px) {
    flex: 1;
    width: 100%;

    &:nth-child(2) {
      flex: 1;
      width: 100%;
      overflow: auto;
    }
  }
`;

export const TicketAreaBox = styled.div`
  margin: 8px;
  padding: 16px;
  flex: 1;

  overflow: hidden;

  background-color: ${colors.grayDark};
  border-radius: 20px;

  @media (max-width: 960px) {
    overflow: auto;

    margin: 8px 0;
  }
`;

export const TicketAreaTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  align-items: center;

  ${fonts.subTitle}
  font-weight: 800;
`;

export const TicketAreaInnerBox = styled.div`
  display: flex;
  height: 48px;
  margin: 12px 0;
  padding: 12px;
  justify-content: space-between;
  align-items: center;

  background-color: ${colors.gray};
  border-radius: 20px;
`;

export const TicketAreaValue = styled.div`
  padding: 8px 16px;

  min-width: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  background-color: ${colors.grayLight};
  border-radius: 12px;

  ${fonts.bigCaption}
`;
export const TicketAreaSubInfo = styled.div`
  padding: 0px 12px;

  color: ${colors.grayLight};

  ${fonts.caption}
`;

export const IceLogo = styled.img`
  margin-left: 8px;

  width: 24px;
  height: 24px;
`;

export const IceLogoSmall = styled.img`
  margin-left: 4px;

  width: 16px;
  height: 16px;
`;

export const TicketLogo = styled.img`
  margin-left: 16px;

  width: 36px;
  height: 32px;
`;

export const TicketAreaSkeletonWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 960px) {
    width: calc(100% - 32px);
  }
`;

export const ConnectMetaMaskButtonWrapper = styled.div`
  z-index: ${zIndexes.frontContent};
  display: flex;
  position: absolute;
  top: calc(50% - 60px);
  flex-direction: column;
  width: 100%;
  height: 120px;

  justify-content: center;
  align-items: center;
`;

export const ConnectMetaMaskButton = styled(OutlineButton)`
  width: 320px;
  height: 64px;

  ${fonts.subTitle};
`;

export const ConnectMetaMaskInfoText = styled.div`
  margin-top: auto;
  margin-bottom: 12px;

  ${fonts.caption};
`;

export const TicketAreaButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

export const LiveIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;

  border-radius: 50%;

  background-color: ${colors.green};
`;
