import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';

import background from 'assets/images/background.png';

const SMALL_SCREEN_BREAKPOINT_WIDTH = '960px';

export const PastLotteriesPageContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 176px);
  display: flex;
  align-items: center;
  flex-direction: column;

  color: ${colors.white};
  background-image: url(${background}),
    linear-gradient(${colors.black}, ${colors.gray});
`;

export const PastLotteriesTablesContainer = styled.div`
  padding: 32px;
  width: 960px;
  margin: 120px auto;

  border-radius: 12px;

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT_WIDTH}) {
    margin: 32px auto;
    width: calc(100% - 64px);
  }
`;

export const PastLotteryItem = styled.div`
  padding: 16px 32px;
  margin: 16px 0;

  background-color: ${colors.grayDark};
  border-radius: 12px;
`;

export const PastLotteryItemTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PastLotteryItemTitle = styled.div`
  ${fonts.bigCaption}
`;

export const PastLotteryItemExpandButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.grayLight};
  ${fonts.title}

  border-radius: 50%;
  border: 2px solid ${colors.grayLight};

  &:hover {
    border-color: ${colors.grayLighter};
    cursor: pointer;
  }
`;

export const ExpandIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const PastLotteryItemSubTitle = styled.div`
  display: flex;
  ${fonts.caption}

  margin-top: 16px;

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT_WIDTH}) {
    flex-direction: column;
  }
`;

export const PastLotteryItemSubTitleItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  padding: 4px;

  border-radius: 12px;

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT_WIDTH}) {
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const PastLotteryItemSubTitleText = styled.div`
  color: ${colors.grayLighter};

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT_WIDTH}) {
    width: 50%;
  }
`;

export const PastLotteryItemSubTitleValue = styled.div`
  display: flex;
  margin-left: 12px;
  padding: 4px 32px;
  justify-content: center;
  align-items: center;
  min-width: 80px;

  border-radius: 12px;
  background-color: ${colors.grayLight};

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT_WIDTH}) {
    width: 50%;
  }
`;

export const PastLotteryItemSubTitleItemLogo = styled.img`
  height: 16px;

  margin-left: 8px;
`;

export const PastLotteryItemSubTitleItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TicketsAreaWrapper = styled.div`
  margin-top: 24px;

  overflow: auto;
`;
