import { TICKET_PRICE } from 'common/constants';

export const formatAmount = (amount) => {
  return amount ? amount.toLocaleString() : 0;
};

export const convertWeiToEth = (amount, isFormatted = false) => {
  const result = amount / 1e18;

  return isFormatted ? formatAmount(Math.floor(result)) : result;
};

export const convertEthToWei = (amount, isFormatted = false) => {
  const result = amount * 1e18;

  return isFormatted ? formatAmount(result) : result;
};

export const calculateAmount = (ticketCount, isFormatted = false) => {
  const result = ticketCount * TICKET_PRICE;

  return isFormatted ? formatAmount(result) : result;
};

export const getTicketCounts = (tickets, account?) => {
  let totalTicketCount = 0;
  let myTicketCount = 0;

  tickets?.forEach((element) => {
    totalTicketCount += element.returnValues.ticketCount * 1;

    if (
      account &&
      element.returnValues.buyer.toUpperCase() === account.toUpperCase()
    ) {
      myTicketCount += element.returnValues.ticketCount * 1;
    }
  });

  return { totalTicketCount, myTicketCount };
};
