import * as S from 'components/skeleton-item/styles';

import * as colors from 'common/colors';

interface SkeletonItemProps {
  count?: number;
  lineColor?: String;
  lineHeight?: number;
}

const SkeletonItem = ({
  count = 1,
  lineColor = colors.grayLight,
  lineHeight = 32,
}: SkeletonItemProps) => (
  <S.SkeletonItemWrapper>
    {Array(count)
      .fill(null)
      .map((_, index) => (
        <S.LineSkeletonItem
          theme={{ main: lineColor }}
          $lineHeight={lineHeight}
          key={index}
        />
      ))}
  </S.SkeletonItemWrapper>
);

export default SkeletonItem;
