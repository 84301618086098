import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';

export const BuyTicketResultModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  padding-top: 300px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const BuyTicketResultModalContent = styled.div`
  background-color: ${colors.grayDark};
  margin: auto;
  padding: 48px;
  border-radius: 12px;
  width: 480px;
  min-height: 240px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BuyTicketResultModalTitleIcon = styled.img`
  width: 48px;
  height: 48px;
`;

export const BuyTicketResultModalTitle = styled.div`
  margin-top: 16px;

  ${fonts.title}
`;

export const BuyTicketResultModalMessage = styled.div`
  margin: 24px 0;
  text-align: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${fonts.subTitle}
`;

export const BuyTicketResultModalExternalLink = styled.a`
  color: ${colors.grayLight};
  margin-bottom: 24px;
`;
