import * as S from 'modules/past-lotteries/styles';

import PastLotteryItem from 'modules/past-lotteries/PastLotteryItem';
import { useEffect } from 'react';

const PastLotteries = ({
  lotteryId,
  lotteryInfo,
  checkLotteryId,
  getTicketsForPastLotteries,
  getLotteryBlockId,
  getLotteryInfo,
}) => {
  useEffect(() => {
    if (!lotteryId) {
      checkLotteryId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lotteryId) {
      for (let i = parseInt(lotteryId); i >= 0; i--) {
        getLotteryBlockId(i).then((response) => {
          getLotteryInfo({
            blockId: response.payload,
            lotteryId: i - 1,
          });
        });
      }
    }
  }, [getLotteryBlockId, getLotteryInfo, lotteryId]);

  const renderPastLotteryItem = () => {
    const pastLotteryItems: React.ReactElement[] = [];

    for (let i = parseInt(lotteryId) - 1; i >= 0; i--) {
      if (lotteryInfo[i]) {
        pastLotteryItems.push(
          <PastLotteryItem
            getTicketsForPastLotteries={getTicketsForPastLotteries}
            getLotteryBlockId={getLotteryBlockId}
            lotteryInfo={lotteryInfo[i]}
            key={`lottery-info-${i}`}
          />
        );
      }
    }

    return pastLotteryItems;
  };

  return (
    <S.PastLotteriesPageContainer>
      <S.PastLotteriesTablesContainer>
        {lotteryId && renderPastLotteryItem()}
      </S.PastLotteriesTablesContainer>
    </S.PastLotteriesPageContainer>
  );
};

export default PastLotteries;
