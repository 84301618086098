import { BLOCK_EXPLORER_URL } from 'common/constants';
import { maskMetaAccount } from 'common/utils/stringUtils';
import { timeSince } from 'common/utils/dateTimeUtils';
import { calculateAmount } from 'common/utils/priceUtils';

import LoadingAnimation from 'components/loading-animation/LoadingAnimation';

import * as S from 'components/tickets-table/styles';

import iceLogo from 'assets/svgs/ice-logo.svg';

const TicketsTable = ({ tickets }) => (
  <S.LastBoughtTicketsWrapper>
    <S.LastBoughtTicketsTableHeaderRow>
      <S.LastBoughtTicketsTableColumn $width={150}>
        Time
      </S.LastBoughtTicketsTableColumn>
      <S.LastBoughtTicketsTableColumn $width={120}>
        Account
      </S.LastBoughtTicketsTableColumn>
      <S.LastBoughtTicketsTableColumn $width={80} $textAlign="right">
        Count
      </S.LastBoughtTicketsTableColumn>
      <S.LastBoughtTicketsTableColumn $width={110} $textAlign="right">
        Amount
      </S.LastBoughtTicketsTableColumn>
      <S.LastBoughtTicketsTableColumn $width={60} $textAlign="right">
        TX
      </S.LastBoughtTicketsTableColumn>
    </S.LastBoughtTicketsTableHeaderRow>

    <S.LastBoughtTicketsRowsWrapper>
      {tickets?.length >= 0 ? (
        <>
          {tickets.length === 0 ? (
            <S.LastBoughtTicketsLoading>
              <S.LoadingInformationText>
                Still waiting first ticket to be bought
              </S.LoadingInformationText>
            </S.LastBoughtTicketsLoading>
          ) : (
            <>
              {tickets
                //.sort().
                .map((ticket) => {
                  const { buyer, ticketCount, timestamp } = ticket.returnValues;

                  return (
                    <S.LastBoughtTicketsTableRow key={ticket.transactionHash}>
                      <S.LastBoughtTicketsTableColumn $width={150}>
                        {timeSince(timestamp)}
                      </S.LastBoughtTicketsTableColumn>
                      <S.LastBoughtTicketsTableColumn $width={120}>
                        {maskMetaAccount(buyer)}
                      </S.LastBoughtTicketsTableColumn>
                      <S.LastBoughtTicketsTableColumn
                        $width={80}
                        $textAlign="right"
                      >
                        {ticketCount}
                      </S.LastBoughtTicketsTableColumn>
                      <S.LastBoughtTicketsTableColumn
                        $width={110}
                        $textAlign="right"
                      >
                        {calculateAmount(ticketCount, true)}{' '}
                        <S.IceLogoSmall src={iceLogo} />
                      </S.LastBoughtTicketsTableColumn>
                      <S.LastBoughtTicketsTableColumn
                        $width={60}
                        $textAlign="right"
                      >
                        <S.LastBoughtTicketsVisitLink
                          href={`${BLOCK_EXPLORER_URL}${ticket.transactionHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                        </S.LastBoughtTicketsVisitLink>
                      </S.LastBoughtTicketsTableColumn>
                    </S.LastBoughtTicketsTableRow>
                  );
                })}
            </>
          )}
        </>
      ) : (
        <S.LastBoughtTicketsLoading>
          <LoadingAnimation size={340} />
          <S.LoadingInformationText>
            We are trying to get ticket informations
          </S.LoadingInformationText>
        </S.LastBoughtTicketsLoading>
      )}
    </S.LastBoughtTicketsRowsWrapper>
  </S.LastBoughtTicketsWrapper>
);

export default TicketsTable;
