import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import PATHS from 'routing/paths';

import HomeContainer from 'modules/home/HomeContainer';
import PastLotteriesContainer from 'modules/past-lotteries/PastLotteriesContainer';

const Routes = () => (
  <ReactRouterRoutes>
    <Route path="/">
      <Route index element={<HomeContainer />} />
      <Route path={PATHS.HOME} element={<HomeContainer />} />
      <Route path={PATHS.PAST_LOTTERIES} element={<PastLotteriesContainer />} />
      <Route path="*" element={<div>404 Not Found Page</div>} />
    </Route>
  </ReactRouterRoutes>
);

export default Routes;
