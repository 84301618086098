import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { reduxStore } from 'state/ReduxStore';

import App from 'App';

import 'index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>
);
