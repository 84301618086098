import styled from 'styled-components';

import * as fonts from 'common/fonts';
import * as colors from 'common/colors';

export const LotteryStateMessageContainer = styled.div`
  margin-top: 48px;
  padding: 12px 32px;

  background-color: ${colors.yellow};
  color: ${colors.grayDark};

  ${fonts.bigCaption}
`;
