import { useCountdown } from 'common/hooks/useCountdown';

import * as S from 'components/countdown/styles';

const FOUR_HOURS_MS = 4 * 60 * 60 * 1000;

const date = new Date();
let daysLeft = (12 - date.getUTCDay());
if(daysLeft >= 7)
  daysLeft -= 7;
let midNight = Date.UTC(
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate() + daysLeft + 1,
  0,
  0,
  0
);

const Countdown = () => {
  const [days, hours, minutes, seconds] = useCountdown(midNight - FOUR_HOURS_MS);

  if (hours === 0 && minutes === 0 && seconds === 0) {
    midNight = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate() + daysLeft + 8,
      0,
      0,
      0
    );
  }

  return (
    <S.CountdownToDrawingContainer id="buy-ticket">
      <S.CountdownTitle>Countdown to Drawing:</S.CountdownTitle>
      <S.CountdownTimer>
        <S.CountdownTimerRow>
          {days} days {hours} hours {minutes} minutes {seconds} seconds
        </S.CountdownTimerRow>
      </S.CountdownTimer>
    </S.CountdownToDrawingContainer>
  );
};

export default Countdown;
