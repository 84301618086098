import { redirectToMetaMaskDownloadPage } from 'common/utils/metaMaskUtils';
import SkeletonItem from 'components/skeleton-item/SkeletonItem';

import * as S from 'modules/home/ticket-area/styles';

const TicketAreaSkeleton = ({
  isMetaMaskInstalled,
  handleConnectMetaMask,
  isWrongNetwork,
  isMetaMaskConnected,
  changeChain,
}) => {
  let connectMetaButton;

  if (!isMetaMaskInstalled) {
    connectMetaButton = {
      onClick: redirectToMetaMaskDownloadPage,
      text: 'Download MetaMask!',
      subText:
        'Download and connect MetaMask to buy tickets and see live stats!',
    };
  } else if (!isMetaMaskConnected) {
    connectMetaButton = {
      onClick: handleConnectMetaMask,
      text: 'Connect MetaMask!',
      subText: 'Connect MetaMask to buy tickets and see live stats!',
    };
  } else if (isWrongNetwork) {
    connectMetaButton = {
      onClick: changeChain,
      text: 'Switch to Polygon Network!',
      subText:
        'Change network to Polygon to buy tickets and see live stats!',
    };
  }

  return (
    <S.TicketAreaSkeletonWrapper>
      <S.ConnectMetaMaskButtonWrapper>
        <S.ConnectMetaMaskButton onClick={connectMetaButton.onClick}>
          {connectMetaButton.text}
        </S.ConnectMetaMaskButton>
        <S.ConnectMetaMaskInfoText>
          {connectMetaButton.subText}
        </S.ConnectMetaMaskInfoText>
      </S.ConnectMetaMaskButtonWrapper>
      <S.TicketAreaWrapper $isMasked>
        <S.TicketAreaColumn>
          <S.TicketAreaBox>
            <SkeletonItem count={2} lineHeight={64} />
          </S.TicketAreaBox>
          <S.TicketAreaBox>
            <SkeletonItem count={3} lineHeight={48} />
          </S.TicketAreaBox>
        </S.TicketAreaColumn>
        <S.TicketAreaColumn>
          <S.TicketAreaBox>
            <SkeletonItem count={8} />
          </S.TicketAreaBox>
        </S.TicketAreaColumn>
      </S.TicketAreaWrapper>
    </S.TicketAreaSkeletonWrapper>
  );
};

export default TicketAreaSkeleton;
