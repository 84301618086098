import plusIcon from 'assets/svgs/math-plus-icon.svg';
import minusIcon from 'assets/svgs/math-minus-icon.svg';

import * as S from 'components/count-input/styles';

const CountInput = ({
  handleIncrement,
  handleDecrement,
  handleOnchange,
  value,
}) => (
  <S.CountInputWrapper>
    <S.CounterButton onClick={handleDecrement}>
      <S.Icon src={minusIcon} />
    </S.CounterButton>
    <S.CountValue value={value} onChange={handleOnchange} />
    <S.CounterButton onClick={handleIncrement}>
      <S.Icon src={plusIcon} />
    </S.CounterButton>
  </S.CountInputWrapper>
);

export default CountInput;
