import * as S from 'modules/home/styles';

const HomePageTitle = () => (
  <S.WelcomeText>
    <S.Title>Welcome to Ice Lottery!</S.Title>
    <S.Subtitle>
      <S.SubtitleRow>
        ICE Lottery is a provably fair and verifiable random lottery game.
      </S.SubtitleRow>
      <S.SubtitleRow>
        Buy a ticket to test your luck and win a big prize. Buy more tickets to
        increase your chance of winning.
      </S.SubtitleRow>
    </S.Subtitle>
  </S.WelcomeText>
);

export default HomePageTitle;
