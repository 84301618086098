import { BUY_TICKET_BUTTON_STATES } from 'common/constants';
import {
  calculateAmount,
  convertEthToWei,
  convertWeiToEth,
} from 'common/utils/priceUtils';
import LoadingAnimation from 'components/loading-animation/LoadingAnimation';
import * as S from 'modules/home/ticket-area/buy-ticket/styles';

let shouldShowApproveButton = false;

const BuyTicket = ({
  balance,
  ticketCount,
  handleBuyTicket,
  buyTicketButtonState,
  allowance,
  handleApproveToken,
  isBuyTicketAllowed,
}) => {
  const ticketPrice = calculateAmount(ticketCount);
  const isButtonReady = buyTicketButtonState === BUY_TICKET_BUTTON_STATES.READY;
  const isBalanceEnough = convertWeiToEth(balance) >= ticketPrice;
  const tokenShouldBeApproved =
    (isBalanceEnough && !allowance) || allowance < convertEthToWei(ticketPrice);

  if (tokenShouldBeApproved) {
    shouldShowApproveButton = true;
  }

  let buttonText;

  if (!isBuyTicketAllowed) {
    buttonText = 'Not Allowed!';
  } else if (!isBalanceEnough) {
    buttonText = 'Insufficient Funds!';
  } else {
    buttonText = 'Buy Ticket(s)!';
  }

  const isBuyTicketDisabled =
    !isBuyTicketAllowed ||
    !isButtonReady ||
    convertWeiToEth(balance) < ticketPrice ||
    !(ticketCount > 0) ||
    !isBalanceEnough ||
    tokenShouldBeApproved;

  return (
    <S.BuyTicketButtonsWrapper>
      {shouldShowApproveButton && (
        <S.BuyTicketButton
          disabled={!tokenShouldBeApproved}
          onClick={tokenShouldBeApproved ? handleApproveToken : handleBuyTicket}
        >
          {isButtonReady ? 'Approve Token' : <LoadingAnimation size={36} />}
        </S.BuyTicketButton>
      )}

      <S.BuyTicketButton
        disabled={isBuyTicketDisabled}
        onClick={handleBuyTicket}
      >
        {isBuyTicketDisabled || isButtonReady ? (
          buttonText
        ) : (
          <LoadingAnimation size={36} />
        )}
      </S.BuyTicketButton>
    </S.BuyTicketButtonsWrapper>
  );
};

export default BuyTicket;
