import { ERROR_CODES, NETWORK_CHAIN_ID } from 'common/constants';

export const requestAccounts = () =>
  window.ethereum.request({
    method: 'eth_requestAccounts',
  });

export const requestChainId = () => {
  return window.ethereum.request({ method: 'eth_chainId' });
};

export const changeChain = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: NETWORK_CHAIN_ID }],
    });
  } catch (switchError: any) {
    window.console.log(switchError);
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === ERROR_CODES.CHAIN_NOT_ADDED) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: NETWORK_CHAIN_ID,
              chainName: 'Polygon',
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: ['https://polygon-rpc.com/'],
              blockExplorerUrls: ['https://polygonscan.com/'],
            },
          ],
        });
      } catch (addError) {
        // handle "add" error
      }
    }
  }
};
