export const black = '#000000';
export const white = '#ffffff';

export const gray = '#232734';
export const grayDark = '#1b1e29';
export const grayLight = '#404557';
export const grayLighter = '#696c80';

export const blue = '#004ce6';
export const blueLight = '#448aff';

export const green = '#00FF00';
export const yellow = '#FEDE00';
