import styled from 'styled-components';

import { Button } from 'components/button/Button';

export const BuyTicketButton = styled(Button)`
  margin-top: 12px;
  width: calc(100% - 8px);
`;

export const BuyTicketButtonsWrapper = styled.div`
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
