import { useState } from 'react';

import { convertTimeStamp } from 'common/utils/dateTimeUtils';
import { convertWeiToEth, formatAmount } from 'common/utils/priceUtils';
import { maskMetaAccount } from 'common/utils/stringUtils';

import * as S from 'modules/past-lotteries/styles';

import iceLogo from 'assets/svgs/ice-logo.svg';
import plusIcon from 'assets/svgs/math-plus-icon.svg';
import minusIcon from 'assets/svgs/math-minus-icon.svg';
import iceLotteryLogo from 'assets/svgs/ice-lottery-logo.svg';
import linkIcon from 'assets/svgs/external-link-icon.svg';
import TicketsTable from 'components/tickets-table/TicketsTable';
import { BLOCK_EXPLORER_URL } from 'common/constants';

const PastLotterySubTitleItem = ({
  text,
  value,
  logo,
  link,
}: {
  text: string;
  value: string;
  logo?: string;
  link?: string;
}) => (
  <S.PastLotteryItemSubTitleItem>
    <S.PastLotteryItemSubTitleText>{text}</S.PastLotteryItemSubTitleText>
    <S.PastLotteryItemSubTitleValue>
      {value}
      {logo && <S.PastLotteryItemSubTitleItemLogo src={logo} />}
      {link && (
        <S.PastLotteryItemSubTitleItemLink href={link} target="_blank">
          <S.PastLotteryItemSubTitleItemLogo src={linkIcon} />
        </S.PastLotteryItemSubTitleItemLink>
      )}
    </S.PastLotteryItemSubTitleValue>
  </S.PastLotteryItemSubTitleItem>
);

const PastLotteryItem = ({
  getTicketsForPastLotteries,
  lotteryInfo,
  getLotteryBlockId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [tickets, setTickets] = useState(null);

  const handleTogglePastLotteryItemInfo = () => {
    setIsExpanded(!isExpanded);

    if (!isExpanded && !tickets) {
      const { lotteryId } = lotteryInfo.returnValues;

      getLotteryBlockId(lotteryId).then((response) => {
        let fromBlockId = parseInt(response.payload);

        while (fromBlockId + 3000 < lotteryInfo.blockNumber) {
          getTicketsForPastLotteries({
            fromBlockId: fromBlockId,
            toBlockId: fromBlockId + 3000,
            lotteryId,
          }).then(({ payload }: { payload: [] }) => {
            // @ts-ignore
            setTickets((prev) => [...(prev || []), ...payload]);
          });
          fromBlockId += 3001;
        }

        getTicketsForPastLotteries({
          fromBlockId: fromBlockId,
          toBlockId: lotteryInfo.blockNumber,
          lotteryId,
        }).then(({ payload }: { payload: [] }) => {
          // @ts-ignore
          setTickets((prev) => [...(prev || []), ...payload]);
        });
      });
    }
  };

  return (
    <S.PastLotteryItem>
      <S.PastLotteryItemTitleArea>
        <S.PastLotteryItemTitle>
          {`Lottery #${lotteryInfo.returnValues.lotteryId} - ${convertTimeStamp(
            lotteryInfo.returnValues.endTime
          )}`}
        </S.PastLotteryItemTitle>
        <S.PastLotteryItemExpandButton
          onClick={handleTogglePastLotteryItemInfo}
        >
          <S.ExpandIcon src={isExpanded ? minusIcon : plusIcon} />
        </S.PastLotteryItemExpandButton>
      </S.PastLotteryItemTitleArea>
      <S.PastLotteryItemSubTitle>
        <PastLotterySubTitleItem
          text="Prize Pot:"
          value={formatAmount(convertWeiToEth(lotteryInfo.returnValues.prize))}
          logo={iceLogo}
        />
        <PastLotterySubTitleItem
          text="Ticket Count:"
          value={lotteryInfo.returnValues.ticketCount}
          logo={iceLotteryLogo}
        />
        <PastLotterySubTitleItem
          text="Winner:"
          value={maskMetaAccount(lotteryInfo.returnValues.winner)}
          link={`${BLOCK_EXPLORER_URL}${lotteryInfo.transactionHash}`}
        />
      </S.PastLotteryItemSubTitle>

      {isExpanded && (
        <S.TicketsAreaWrapper>
          <TicketsTable tickets={tickets} />
        </S.TicketsAreaWrapper>
      )}
    </S.PastLotteryItem>
  );
};

export default PastLotteryItem;
