import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';

import background from 'assets/images/background.png';

const TITLE_WRAPPER_WIDTH = '1400px';
const HEADER_PARTS_BREAKPOINT_WIDTH = '720px';

export const HomePageTitleWrapper = styled.div`
  max-width: ${TITLE_WRAPPER_WIDTH};
  width: 100%;
  display: flex;
  margin: 64px 0;

  @media (max-width: ${TITLE_WRAPPER_WIDTH}) {
    flex-direction: column;
  }
`;

export const HomePageContainer = styled.div`
  align-items: center;
  height: 100%;
  width: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  padding: 32px;

  color: ${colors.white};
  background-image: url(${background}),
    linear-gradient(${colors.black}, ${colors.gray});

  @media (max-width: ${TITLE_WRAPPER_WIDTH}) {
    padding: 16px;
    width: calc(100% - 32px);
  }
`;

export const WelcomeText = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 64px;

  @media (max-width: ${TITLE_WRAPPER_WIDTH}) {
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  max-width: 400px;
  width: 100%;

  ${fonts.bigTitle}

  @media (max-width: ${HEADER_PARTS_BREAKPOINT_WIDTH}) {
    ${fonts.title}
  }
`;

export const Subtitle = styled.div`
  margin-top: 32px;

  ${fonts.subTitle}

  @media (max-width: ${HEADER_PARTS_BREAKPOINT_WIDTH}) {
    margin-top: 16px;

    ${fonts.bigCaption}
  }
`;

export const SubtitleRow = styled.div`
  margin-top: 12px;
`;

export const PrizePoolInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 32px 48px;
  width: 560px;

  background-color: ${colors.grayDark};

  border-radius: 12px;

  @media (max-width: ${TITLE_WRAPPER_WIDTH}) {
    margin-top: 64px;
  }

  @media (max-width: ${HEADER_PARTS_BREAKPOINT_WIDTH}) {
    width: calc(100% - 16px);
    padding: 8px;
  }
`;

export const PrizePoolInfoTitle = styled.div`
  margin-bottom: 24px;

  ${fonts.title}
`;

export const PrizePoolInfoPrice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;

  background-color: ${colors.grayLight};
  border-radius: 12px;

  white-space: nowrap;

  ${fonts.bigTitle}

  @media (max-width: ${HEADER_PARTS_BREAKPOINT_WIDTH}) {
    padding: 8px;

    ${fonts.title}
  }
`;

export const PrizePoolIceLogo = styled.img`
  width: 64px;
  height: 64px;

  margin-left: 24px;

  @media (max-width: ${HEADER_PARTS_BREAKPOINT_WIDTH}) {
    width: 32px;
    height: 32px;

    margin-left: 12px;
  }
`;

export const PrizePoolPreviousInfo = styled.a`
  margin-top: 16px;

  color: ${colors.grayLighter};
`;
