import styled from 'styled-components';

import * as fonts from 'common/fonts';
import * as colors from 'common/colors';

export const CountInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CountValue = styled.input`
  margin: 0 14px;

  width: 48px;

  outline: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  background: 0 0;
  box-shadow: none;
  text-align: center;

  color: ${colors.white};

  ${(props) => {
    if (props.value) {
      if (props.value > 99999) {
        return fonts.body;
      } else if (props.value > 9999) {
        return fonts.caption;
      } else if (props.value > 99) {
        return fonts.subTitle;
      }
    }

    return fonts.title;
  }}
`;

export const CounterButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.grayLight};
  ${fonts.title}

  border-radius: 50%;
  border: 2px solid ${colors.grayLight};

  &:hover {
    border-color: ${colors.grayLighter};
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;
