import styled from 'styled-components';

export const SkeletonItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const LineSkeletonItem = styled.div<{
  $lineHeight: number;
}>`
  width: 100%;
  height: ${(props) => props.$lineHeight}px;
  margin: 16px 0;

  border-radius: 12px;

  background-color: ${(props) => props.theme.main};
`;
