import * as S from 'components/footer/styles';

const Footer = () => (
  <S.FooterContainer>
    <S.CopyrightText>
      <div>
        Ice Lottery is a decentralized project that uses ICE Token and is not
        affiliated with Decentral Games.
      </div>
      <div>
        Use Ice Lottery in line with the legislation of your country of
        residence.
      </div>
      <div>
        DM @IceLottery in twitter for any inquiries.
      </div>
    </S.CopyrightText>
  </S.FooterContainer>
);

export default Footer;
