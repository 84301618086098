import { connect } from 'react-redux';

import {
  requestAccounts,
  allowanceCheck,
  balanceCheck,
  requestChainId,
  getLotteryInfo,
} from 'state/slices/accountSlice';

import Header from 'components/header/Header';

const mapStateToProps = (state) => ({
  accounts: state.account.accounts,
  chainId: state.account.chainId,
  lotteryId: state.account.lotteryId,
  currentLotteryBlock: state.account.currentLotteryBlock,
});

const mapDispatchToProps = {
  requestAccounts,
  allowanceCheck,
  balanceCheck,
  requestChainId,
  getLotteryInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
