import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';

const SMALL_SCREEN_BREAKPOINT = '760px';

export const CountdownToDrawingContainer = styled.div`
  padding: 32px 64px;
  border: 1px solid ${colors.white};
  border-radius: 12px;
  margin: auto;
  max-width: 640px;
  width: 100%;

  text-align: center;

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    padding: 8px 12px;

    width: calc(100% - 32px);
  }
`;

export const CountdownTitle = styled.div`
  ${fonts.subTitle}

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    ${fonts.bigCaption}
  }
`;

export const CountdownTimer = styled.div`
  margin-top: 24px;

  ${fonts.title}

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    margin-top: 8px;
  }
`;

export const CountdownTimerRow = styled.div``;
