import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';

export const FooterContainer = styled.div`
  padding: 16px 0 48px;
  width: 100%;
  display: flex;
  bottom: 0;

  align-items: center;
  text-align: center;

  background-color: ${colors.gray};
  color: ${colors.grayLighter};
`;

export const CopyrightText = styled.div`
  ${fonts.body}

  width: 100%;

  div:last-child {
    margin-top: 16px;
  }

  div {
    a {
      color: ${colors.grayLighter};
    }
  }
`;
