import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  height: 48px;
  padding: 16px;

  background-image: linear-gradient(
    105deg,
    ${colors.blueLight} 3%,
    ${colors.blue}
  );
  color: ${colors.white};

  ${fonts.caption}

  border: none;
  border-radius: 12px;

  cursor: pointer;

  &:hover {
    background-image: none;
    background-color: ${colors.blue};
  }

  &:disabled {
    background-color: ${colors.grayLight};
    background-image: none;
    color: ${colors.gray};

    cursor: not-allowed;
  }
`;

export const OutlineButton = styled.button`
  margin: 4px;
  padding: 16px;

  background: transparent;

  border: 2px solid ${colors.white};
  border-radius: 12px;

  color: ${colors.white};

  cursor: pointer;

  ${fonts.caption}

  &:hover {
    border-color: ${colors.white};
    border-width: 3px;
  }
`;
