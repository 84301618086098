import Web3 from 'web3';
import { PastEventOptions } from 'web3-eth-contract';

import {
  HOH_TOKEN_ABI,
  TOKEN_CONTRACT_ADDRESS,
  LOTTERY_CONTRACT_ADDRESS,
  UNLIMITED_VALUE,
  LOTTERY_ABI,
} from 'common/constants';

const provider = new Web3(Web3.givenProvider);

const tokenContract = new provider.eth.Contract(
  HOH_TOKEN_ABI,
  TOKEN_CONTRACT_ADDRESS
);

const lotteryContract = new provider.eth.Contract(
  LOTTERY_ABI,
  LOTTERY_CONTRACT_ADDRESS
);

export const allowanceCheck = (account: String) => {
  return tokenContract.methods
    .allowance(account, LOTTERY_CONTRACT_ADDRESS)
    .call();
};

export const approve = (account: String) => {
  return provider.eth
    .getGasPrice()
    .then((result: any) =>
      tokenContract.methods
        .approve(LOTTERY_CONTRACT_ADDRESS, UNLIMITED_VALUE)
        .send({ from: account, gasPrice: Math.floor(result * 1.2) })
    );
};

export const balanceCheck = (account: String) => {
  return tokenContract.methods.balanceOf(account).call();
};

//Verilen blockId sonrasındaki tüm lotteryler gelir.
export const previousLotteries = (blockId: number) => {
  const options: PastEventOptions = {
    fromBlock: blockId,
    toBlock: 'latest',
  };

  return lotteryContract.getPastEvents('PrizeDistributed', options);
};

export const getTickets = ({ fromBlockId, toBlockId, lotteryId }) => {
  console.log("from, to, margin", fromBlockId, toBlockId, toBlockId - fromBlockId);
  const options: PastEventOptions = {
    filter: {
      lotteryId, //Güncel lottery veya eski tarihli lottery ler için biletler istenebilir.
    },
    fromBlock: fromBlockId,
    toBlock: toBlockId,
  };

  return lotteryContract.getPastEvents('TicketBought', options);
};

export const buyTicket = ({ ticketCount, account }) => {
  return provider.eth
    .getGasPrice()
    .then((result: any) =>
      lotteryContract.methods
        .buyTicket(ticketCount)
        .send({ from: account, gasPrice: Math.floor(result * 1.2) })
    );
};

export const checkLotteryId = () => {
  return lotteryContract.methods.currentLotteryId().call();
};

export const getLatestBlockNumber = () => {
  return provider.eth.getBlockNumber();
};

export const getLotteryState = () => {
  return lotteryContract.methods.lotteryState().call();
};

export const getTestModeStatus = () => {
  return lotteryContract.methods.isTestMode().call();
};

export const checkIsTestPlayer = (account) => {
  return lotteryContract.methods.testPlayers(account).call();
};

export const getLotteryBlockId = (lotteryId) => {
  return lotteryContract.methods.lotteryBlockNumber(lotteryId).call();
};

export const getLotteryInfo = (blockId: number, lotteryId: string) => {
  const options: PastEventOptions = {
    fromBlock: blockId - 999,
    toBlock: blockId,
    filter: {
      lotteryId,
    },
  };

  return lotteryContract.getPastEvents('PrizeDistributed', options);
};
