import { configureStore } from '@reduxjs/toolkit';

import { reducer as accountReducer } from 'state/slices/accountSlice';

export const reduxStore = configureStore({
  reducer: {
    account: accountReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof reduxStore.getState>;
