import {
  calculateAmount,
  convertWeiToEth,
  getTicketCounts,
} from 'common/utils/priceUtils';

import iceLogo from 'assets/svgs/ice-logo.svg';

import * as S from 'modules/home/styles';
import { BLOCK_EXPLORER_URL } from 'common/constants';

const PrizePoolInfo = ({ tickets, lastLotteryInfo }) => {
  if (!tickets) {
    return null;
  }

  const { totalTicketCount } = getTicketCounts(tickets);

  return (
    <S.PrizePoolInfoContainer>
      <S.PrizePoolInfoTitle>Active Prize Pool</S.PrizePoolInfoTitle>
      <S.PrizePoolInfoPrice>
        {calculateAmount(totalTicketCount, true)} ICE
        <S.PrizePoolIceLogo src={iceLogo} />
      </S.PrizePoolInfoPrice>
      {lastLotteryInfo && (
        <S.PrizePoolPreviousInfo
          href={`${BLOCK_EXPLORER_URL}${lastLotteryInfo.transactionHash}`}
          target="_blank"
        >
          {`Previously Distributed Prize: ${convertWeiToEth(
            lastLotteryInfo?.returnValues?.prize,
            true
          )} ICE`}
        </S.PrizePoolPreviousInfo>
      )}
    </S.PrizePoolInfoContainer>
  );
};

export default PrizePoolInfo;
