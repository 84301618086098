import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';
import * as zIndexes from 'common/zIndexes';

const HEADER_SMALL_SCREEN_BREAK_POINT = '1080px';

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: ${zIndexes.header};
  padding: 16px 64px;
  display: flex;

  justify-content: space-between;
  align-items: center;

  background-color: ${colors.black};
  color: ${colors.white};

  @media (max-width: ${HEADER_SMALL_SCREEN_BREAK_POINT}) {
    padding: 8px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 90px;
  height: 60px;

  @media (max-width: ${HEADER_SMALL_SCREEN_BREAK_POINT}) {
    width: 60px;
    height: 40px;
  }
`;

export const Title = styled.div`
  margin-left: 12px;

  ${fonts.title}

  @media (max-width: ${HEADER_SMALL_SCREEN_BREAK_POINT}) {
    ${fonts.titleSmallScreen}
  }
`;

export const NavTitle = styled.a`
  margin-left: 32px;

  padding: 8px 16px;

  color: ${colors.grayLighter};
  text-decoration: none;

  ${fonts.subTitle}

  cursor: pointer;

  &:hover {
    color: ${colors.white};
  }

  @media (max-width: ${HEADER_SMALL_SCREEN_BREAK_POINT}) {
    display: none;
    padding: 0;
    margin-left: 16px;

    ${fonts.bigCaption}
  }
`;

export const ProfileNameArea = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;

  border: 2px solid ${colors.gray};
  border-radius: 12px;

  ${fonts.caption};

  @media (max-width: ${HEADER_SMALL_SCREEN_BREAK_POINT}) {
    padding: 8px;
  }
`;

export const ProfilePicture = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 16px;

  @media (max-width: 360px) {
    display: none;
  }
`;
