import { connect } from 'react-redux';

import Home from 'modules/home//Home';

import {
  approve,
  getTickets,
  buyTicket,
  checkLotteryId,
  requestAccounts,
  requestChainId,
  balanceCheck,
  getLotteryState,
  resetBuyTicketResult,
  getTestModeStatus,
  checkIsTestPlayer,
  getLatestBlockNumber,
  getLotteryBlockId,
  resetTickets,
} from 'state/slices/accountSlice';

const mapStateToProps = (state) => ({
  accounts: state.account.accounts,
  allowance: state.account.allowance,
  balance: state.account.balance,
  tickets: state.account.tickets,
  chainId: state.account.chainId,
  buyTicketButtonState: state.account.buyTicketButtonState,
  buyTicketResult: state.account.buyTicketResult,
  lotteryState: state.account.lotteryState,
  isTestMode: state.account.isTestMode,
  isTestPlayer: state.account.isTestPlayer,
  latestBlockNumber: state.account.latestBlockNumber,
  lotteryId: state.account.lotteryId,
  lotteryInfo: state.account.lotteryInfo,
});

const mapDispatchToProps = {
  approve,
  getTickets,
  resetTickets,
  buyTicket,
  checkLotteryId,
  requestAccounts,
  requestChainId,
  balanceCheck,
  getLotteryState,
  resetBuyTicketResult,
  getTestModeStatus,
  checkIsTestPlayer,
  getLatestBlockNumber,
  getLotteryBlockId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
