import { OutlineButton } from 'components/button/Button';
import * as S from 'components/buy-ticket-result-modal/styles';

import successIcon from 'assets/svgs/success.svg';
import errorIcon from 'assets/svgs/error.svg';

import { BLOCK_EXPLORER_URL } from 'common/constants';

const BuyTicketResultModal = ({ buyTicketResult, resetBuyTicketResult }) => {
  if (!buyTicketResult) {
    return null;
  }

  const modalData = buyTicketResult?.status
    ? {
        titleIcon: successIcon,
        title: 'Congratulations!',
        message: "You've successfully bought ticket(s)",
        externalLinkUrl: `${BLOCK_EXPLORER_URL}${buyTicketResult.transactionHash}`,
        externalLinkText: 'View on block explorer',
      }
    : {
        titleIcon: errorIcon,
        title: 'Failed',
        message: buyTicketResult.message,
      };

  return (
    <S.BuyTicketResultModalWrapper>
      <S.BuyTicketResultModalContent>
        <S.BuyTicketResultModalTitleIcon src={modalData.titleIcon} />
        <S.BuyTicketResultModalTitle>
          {modalData.title}
        </S.BuyTicketResultModalTitle>
        <S.BuyTicketResultModalMessage>
          {modalData.message}
        </S.BuyTicketResultModalMessage>
        {modalData.externalLinkUrl && (
          <S.BuyTicketResultModalExternalLink
            href={modalData.externalLinkUrl}
            target="_blank"
          >
            {modalData.externalLinkText}
          </S.BuyTicketResultModalExternalLink>
        )}
        <OutlineButton onClick={resetBuyTicketResult}>Continue</OutlineButton>
      </S.BuyTicketResultModalContent>
    </S.BuyTicketResultModalWrapper>
  );
};

export default BuyTicketResultModal;
