import * as S from 'components/join-us-banner/styles';

import discordImg from 'assets/svgs/discord-mark-blue.svg';
import twitterImg from 'assets/svgs/twitter-logo.svg';
import docsImg from 'assets/svgs/docs-logo.svg';

const JoinUsBanner = () => (
  <S.JoinUsBannerContainer>
    <S.Title>
      Please join one of our communities or read Docs to learn more about ICE
      Lottery.
    </S.Title>
    <S.LinksRow>
      <S.IconWrapperLink
        href="https://discord.gg/decentralgames"
        target="_blank"
      >
        <S.Icon src={discordImg} alt="discord-logo" />
      </S.IconWrapperLink>
      <S.IconWrapperLink href="https://twitter.com/IceLottery" target="_blank">
        <S.Icon src={twitterImg} alt="twitter-logo" />
      </S.IconWrapperLink>
      <S.IconWrapperLink href="https://docs.icelottery.net/" target="_blank">
        <S.Icon src={docsImg} alt="docs-logo" />
      </S.IconWrapperLink>
    </S.LinksRow>
  </S.JoinUsBannerContainer>
);

export default JoinUsBanner;
