import { useEffect, useMemo, useState } from 'react';

import { LOTTERY_STATES, NETWORK_CHAIN_ID } from 'common/constants';

import { isMetaMaskInstalled } from 'common/utils/metaMaskUtils';

import HomePageTitle from 'modules/home/HomePageTitle';
import TicketArea from 'modules/home/ticket-area/TicketArea';
import TicketAreaSkeleton from 'modules/home/ticket-area/TicketAreaSkeleton';

import BuyTicketResultModal from 'components/buy-ticket-result-modal/BuyTicketResultModal';
import Countdown from 'components/countdown/Countdown';
import JoinUsBanner from 'components/join-us-banner/JoinUsBanner';
import LotteryStateMessage from 'components/lottery-state-message/LotteryStateMessage';

// TODO: move to account slice
import { changeChain } from 'apis/ethereumApi';

import * as S from 'modules/home/styles';
import { getLatestBlockNumber } from 'apis/web3Api';
import PrizePoolInfo from './PrizePoolInfo';

var timer: NodeJS.Timer;
var oldBalance: number;

const Home = ({
  accounts,
  allowance,
  approve,
  balance,
  getTickets,
  resetTickets,
  buyTicket,
  checkLotteryId,
  requestAccounts,
  requestChainId,
  balanceCheck,
  tickets,
  chainId,
  getLotteryState,
  buyTicketButtonState,
  buyTicketResult,
  resetBuyTicketResult,
  lotteryState,
  getTestModeStatus,
  checkIsTestPlayer,
  isTestMode,
  isTestPlayer,
  lotteryInfo,
  getLotteryBlockId,
}) => {
  const [ticketCount, setTicketCount] = useState(1);
  const [lotteryId, setLotteryId] = useState(null);
  let lastCheckedBlockNumber = 0;

  // reset tickets on component unmount
  useEffect(
    () => () => {
      resetTickets();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (oldBalance !== balance) {
      clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);

  const handleTicketCountTextChange = (value: number) => {
    if (value >= 0) {
      setTicketCount(value);
    }
  };

  const isMetaMaskConnected = useMemo(() => accounts?.length > 0, [accounts]);

  const handleBuyTicket = () => {
    buyTicket({ ticketCount, account: accounts[0] });
  };

  const handleApproveToken = () => {
    approve(accounts[0]);
  };

  const getLatestTickets = () => {
    getLotteryState();
    if (!lotteryId) {
      checkLotteryId().then((result: any) => {
        setLotteryId(result.payload);
        getTicketsAndLotteryBlock(result.payload);
      });
    } else getTicketsAndLotteryBlock(lotteryId);
  };

  const getTicketsAndLotteryBlock = (lotteryId) => {
    if (lastCheckedBlockNumber === 0) {
      getLotteryBlockId(lotteryId).then((result) => {
        lastCheckedBlockNumber = result.payload;
        getTicketsSince(lotteryId, lastCheckedBlockNumber * 1);
      });
    } else getTicketsSince(lotteryId, lastCheckedBlockNumber * 1 + 1);
  };

  const getTicketsSince = (lotteryId, fromBlockId) => {
    getLatestBlockNumber().then((result: number) => {
      if (result > fromBlockId) {
        while (fromBlockId + 3000 < result) {
          getTickets({
            fromBlockId: fromBlockId,
            toBlockId: fromBlockId + 3000,
            lotteryId,
          });
          fromBlockId += 3001;
        }
        getTickets({ fromBlockId: fromBlockId, toBlockId: result, lotteryId });
        lastCheckedBlockNumber = result;
      }
    });
  };

  const resetBuyTicketResultHome = () => {
    if (buyTicketResult?.status) {
      oldBalance = balance;
      timer = setInterval(() => {
        balanceCheck(accounts[0]);
      }, 5000);
    }
    resetBuyTicketResult();
  };

  const handleConnectMetaMask = () => {
    requestAccounts();
    requestChainId();
    balanceCheck(accounts[0]);
  };

  const handleControlTestMode = () => {
    getTestModeStatus().then((result) => {
      if (result.payload) {
        checkIsTestPlayer(accounts[0]);
      }
    });
  };

  const isWrongNetwork = chainId !== NETWORK_CHAIN_ID;
  const isMetaReady =
    isMetaMaskInstalled() && isMetaMaskConnected && !isWrongNetwork;

  const lotteryStateMessage = useMemo(() => {
    let message;

    if (lotteryState === LOTTERY_STATES.PICKING_WINNER) {
      message = 'The winner is being selected! Hold On!';
    } else if (lotteryState === LOTTERY_STATES.PICKING_WINNER) {
      message = 'The winner is being selected! Hold On!';
    } else if (lotteryState === LOTTERY_STATES.CLOSED) {
      message =
        'Ice Lottery temporarily closed, try again later. Please follow our twitter account for updates.';
    } else if (lotteryState === LOTTERY_STATES.SUSPENDED) {
      message =
        'Buying ticket is temporarily suspended, try again later. Please feel free to join our discord for more information.';
    } else if (isTestMode) {
      message =
        'Lottery is in test mode. Only players in the whitelist can buy ticket.';
    }

    return message;
  }, [isTestMode, lotteryState]);

  const isCountDownClosed =
    lotteryState === LOTTERY_STATES.CLOSED ||
    lotteryState === LOTTERY_STATES.PICKING_WINNER;

  return (
    <S.HomePageContainer>
      <S.HomePageTitleWrapper>
        <HomePageTitle />
        <PrizePoolInfo
          tickets={tickets}
          lastLotteryInfo={lotteryId && lotteryInfo[parseInt(lotteryId) - 1]}
        />
      </S.HomePageTitleWrapper>

      {!isCountDownClosed && <Countdown />}

      {lotteryStateMessage && (
        <LotteryStateMessage message={lotteryStateMessage} />
      )}

      {isMetaReady ? (
        <TicketArea
          handleTicketCountTextChange={handleTicketCountTextChange}
          ticketCount={ticketCount}
          allowance={allowance}
          handleApproveToken={handleApproveToken}
          balance={balance}
          handleBuyTicket={handleBuyTicket}
          getLatestTickets={getLatestTickets}
          tickets={tickets}
          accounts={accounts}
          lotteryId={lotteryId}
          buyTicketButtonState={buyTicketButtonState}
          handleControlTestMode={handleControlTestMode}
          lotteryState={lotteryState}
          isTestMode={isTestMode}
          isTestPlayer={isTestPlayer}
        />
      ) : (
        <TicketAreaSkeleton
          isMetaMaskInstalled={isMetaMaskInstalled()}
          handleConnectMetaMask={handleConnectMetaMask}
          isWrongNetwork={isWrongNetwork}
          isMetaMaskConnected={isMetaMaskConnected}
          changeChain={changeChain}
        />
      )}
      <JoinUsBanner />
      <BuyTicketResultModal
        buyTicketResult={buyTicketResult}
        resetBuyTicketResult={resetBuyTicketResultHome}
      />
    </S.HomePageContainer>
  );
};

export default Home;
