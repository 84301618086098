import styled from 'styled-components';

import * as fonts from 'common/fonts';
import * as colors from 'common/colors';

const JOIN_US_WIDTH_BREAKPOINT = '500px';

export const JoinUsBannerContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  ${fonts.subTitle}

  @media (max-width: ${JOIN_US_WIDTH_BREAKPOINT}) {
    ${fonts.bigCaption}

    text-align: center;
  }
`;

export const LinksRow = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const IconWrapperLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  height: 64px;
  margin: 0 24px;
  padding: 16px;

  cursor: pointer;

  &:hover {
    border: 2px solid ${colors.grayLight};
    border-radius: 50%;
    padding: 14px;
  }

  @media (max-width: ${JOIN_US_WIDTH_BREAKPOINT}) {
    width: 48px;
    height: 48px;
    margin: 0 8px;
  }
`;

export const Icon = styled.img`
  width: 64px;
  height: 64px;

  @media (max-width: ${JOIN_US_WIDTH_BREAKPOINT}) {
    width: 48px;
    height: 48px;
  }
`;
