import { useEffect } from 'react';

import { LOTTERY_STATES, MAX_TICKET_LIMIT } from 'common/constants';

import CountInput from 'components/count-input/CountInput';

import BuyTicket from 'modules/home/ticket-area/buy-ticket/BuyTicket';

import * as S from 'modules/home/ticket-area/styles';

import iceLogo from 'assets/svgs/ice-logo.svg';
import iceLotteryLogo from 'assets/svgs/ice-lottery-logo.svg';
import LoadingAnimation from 'components/loading-animation/LoadingAnimation';
import {
  calculateAmount,
  convertWeiToEth,
  getTicketCounts,
} from 'common/utils/priceUtils';
import TicketsTable from 'components/tickets-table/TicketsTable';

const BuyTicketArea = ({
  handleTicketCountTextChange,
  ticketCount,
  allowance,
  handleBuyTicket,
  balance,
  handleApproveToken,
  buyTicketButtonState,
  isBuyTicketAllowed,
}) => (
  <>
    <S.TicketAreaInnerBox>
      Ticket Count:
      <CountInput
        handleIncrement={() =>
          ticketCount < MAX_TICKET_LIMIT &&
          handleTicketCountTextChange(ticketCount + 1)
        }
        handleDecrement={() => handleTicketCountTextChange(ticketCount - 1)}
        handleOnchange={(event) => {
          if (!event.target.value) {
            handleTicketCountTextChange(0);
          }

          const parsed = parseInt(event.target.value);

          if (!isNaN(parsed)) {
            if (parsed <= MAX_TICKET_LIMIT) {
              handleTicketCountTextChange(parsed);
            } else {
              handleTicketCountTextChange(500);
            }
          }
        }}
        value={ticketCount}
      />
    </S.TicketAreaInnerBox>
    <S.TicketAreaInnerBox>
      Total Price:
      <S.TicketAreaValue>
        {calculateAmount(ticketCount, true)} ICE <S.IceLogo src={iceLogo} />
      </S.TicketAreaValue>
    </S.TicketAreaInnerBox>
    <S.TicketAreaSubInfo>
      Balance: {convertWeiToEth(balance, true)} ICE
    </S.TicketAreaSubInfo>
    <BuyTicket
      balance={balance}
      allowance={allowance}
      handleBuyTicket={handleBuyTicket}
      handleApproveToken={handleApproveToken}
      ticketCount={ticketCount}
      buyTicketButtonState={buyTicketButtonState}
      isBuyTicketAllowed={isBuyTicketAllowed}
    />
  </>
);

const TotalTicketCount = ({ tickets, account }) => {
  if (!tickets) {
    return (
      <>
        <S.TicketAreaInnerBox>
          Prize Pool:
          <S.TicketAreaValue>
            <LoadingAnimation />
          </S.TicketAreaValue>
        </S.TicketAreaInnerBox>
        <S.TicketAreaInnerBox>
          Ticket Count:
          <S.TicketAreaValue>
            <LoadingAnimation />
          </S.TicketAreaValue>
        </S.TicketAreaInnerBox>
        <S.TicketAreaInnerBox>
          My Tickets:
          <S.TicketAreaValue>
            <LoadingAnimation />
          </S.TicketAreaValue>
        </S.TicketAreaInnerBox>
      </>
    );
  }

  const { totalTicketCount, myTicketCount } = getTicketCounts(tickets, account);

  return (
    <div>
      <S.TicketAreaInnerBox>
        Prize Pool:
        <S.TicketAreaValue>
          {calculateAmount(totalTicketCount, true)} ICE{' '}
          <S.IceLogo src={iceLogo} />
        </S.TicketAreaValue>
      </S.TicketAreaInnerBox>
      <S.TicketAreaInnerBox>
        Ticket Count:
        <S.TicketAreaValue>
          {totalTicketCount} <S.TicketLogo src={iceLotteryLogo} />
        </S.TicketAreaValue>
      </S.TicketAreaInnerBox>
      <S.TicketAreaInnerBox>
        My Tickets:
        <S.TicketAreaValue>
          {myTicketCount} <S.TicketLogo src={iceLotteryLogo} />
        </S.TicketAreaValue>
      </S.TicketAreaInnerBox>
    </div>
  );
};

const TicketArea = ({
  handleTicketCountTextChange,
  ticketCount,
  allowance,
  handleApproveToken,
  balance,
  handleBuyTicket,
  getLatestTickets,
  tickets,
  accounts,
  lotteryId,
  buyTicketButtonState,
  handleControlTestMode,
  lotteryState,
  isTestMode,
  isTestPlayer,
}) => {
  useEffect(() => {
    getLatestTickets();
    handleControlTestMode();

    const interval = setInterval(function () {
      getLatestTickets();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBuyTicketAllowed =
    lotteryState === LOTTERY_STATES.OPEN &&
    (!isTestMode || (isTestMode && isTestPlayer));

  return (
    <S.TicketAreaWrapper>
      <S.TicketAreaColumn>
        <S.TicketAreaBox>
          <S.TicketAreaTitle>Buy Ticket</S.TicketAreaTitle>
          <BuyTicketArea
            handleTicketCountTextChange={handleTicketCountTextChange}
            ticketCount={ticketCount}
            allowance={allowance}
            handleApproveToken={handleApproveToken}
            balance={balance}
            handleBuyTicket={handleBuyTicket}
            buyTicketButtonState={buyTicketButtonState}
            isBuyTicketAllowed={isBuyTicketAllowed}
          />
        </S.TicketAreaBox>
        <S.TicketAreaBox>
          <S.TicketAreaTitle>
            <S.LiveIcon />
            Lottery #{lotteryId ?? <LoadingAnimation />} Live Stats
          </S.TicketAreaTitle>
          <TotalTicketCount tickets={tickets} account={accounts[0]} />
        </S.TicketAreaBox>
      </S.TicketAreaColumn>
      <S.TicketAreaColumn>
        <S.TicketAreaBox>
          <S.TicketAreaTitle>
            <S.LiveIcon />
            Lottery #{lotteryId ?? <LoadingAnimation />} Tickets
          </S.TicketAreaTitle>
          <TicketsTable tickets={tickets} />
        </S.TicketAreaBox>
      </S.TicketAreaColumn>
    </S.TicketAreaWrapper>
  );
};

export default TicketArea;
