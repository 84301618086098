import styled from 'styled-components';

import * as colors from 'common/colors';
import * as fonts from 'common/fonts';

export const LastBoughtTicketsLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75%;
`;

export const LastBoughtTicketsWrapper = styled.div`
  width: 570px;
  margin: 0 auto;
`;

export const LastBoughtTicketsTableHeaderRow = styled.div`
  display: flex;
  margin: 8px 0;
  padding: 8px;
  border-radius: 12px;
  border: 2px solid ${colors.gray};
`;

export const LastBoughtTicketsTableRow = styled.div`
  display: flex;
  margin: 4px 0;
  padding: 8px;
  border-radius: 12px;

  background-color: ${colors.gray};
`;

export const LastBoughtTicketsTableColumn = styled.div<{
  $width?: number;
  $textAlign?;
}>`
  display: flex;
  width: ${(props) => (props.$width ? `${props.$width}px` : 'auto')};

  align-items: center;
  text-align: ${(props) => props.$textAlign || 'left'};
  ${(props) => props.$textAlign === 'right' && 'justify-content: flex-end'};
  ${(props) => props.$textAlign === 'center' && 'justify-content: center'};
`;

export const LastBoughtTicketsRowsWrapper = styled.div`
  max-height: 540px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    border: 3px solid transparent;
    padding: 2px 6px;
    box-shadow: inset 0 0 10px 10px ${colors.gray};
  }

  &::-webkit-scrollbar {
    width: 8px;
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px ${colors.grayLight};
    border: 3px solid transparent;
  }
`;

export const LastBoughtTicketsVisitLink = styled.a`
  color: ${colors.blue};

  text-decoration: none;
`;

export const LoadingInformationText = styled.div`
  margin-top: 16px;
  width: 50%;
  text-align: center;

  color: ${colors.grayLight};
  ${fonts.subTitle};
`;

export const IceLogoSmall = styled.img`
  margin-left: 4px;

  width: 16px;
  height: 16px;
`;
